<template>
  <div style="padding-bottom:56px;">
    <template v-if="detail.id">
      <template v-if="detail.loading">
        <component :is="myComponent" :myhdinfo="myhdinfo" :hd="detail" v-if="myComponent && myhdinfo.statistics" ref="activity" :goodList="goodList"/>
        
        <div class="footer">
          <p>访问量：{{detail.amount}}</p>
          <p v-if="detail.is_copyright">{{copyright}}</p>
        </div>
        
        <van-popup value position="bottom" :overlay="false" :lock-scroll="false" class="footerAction" v-if="detail.state==1">
          <van-row type="flex" justify="center">
            <van-col span="12"><van-button type="info" block class="btn1" @click="navigateTo">我的奖励</van-button></van-col>
            <van-col span="12"><van-button type="warning" block class="btn2" @click="relay">转发视频</van-button></van-col>
          </van-row>
        </van-popup>
      </template>
      <div v-else class="font-color-gray font-size-20 t-c" style="padding-top:100px;">活动暂停中，请等待恢复</div>

    </template>
 
  </div>
</template>

<script>
  import {gethdcomponents} from '@/utils/util'
  import {GetHdInfo, GetgoodList, GetShareSignature,GetVideoExternal} from './services/services'
  import {mapGetters, mapMutations} from "vuex";
export default {
  name:'douyinIndex',
  components: {},
  data() {
    return {
      hdid:undefined,
      myComponent:null,
      goodList:[],//活动商品
      detail:{},
      copyright:process.env.VUE_APP_COPYRIGHT,
      myhdinfo:{},
      loading:false
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
  },
  activated() {
    this.setBgColor()
  },
  deactivated() {
    document.body.removeAttribute('style')
  },
  created(){
    this.hdid=parseInt(this.$route.params.hdid)
  },
  mounted() {
    this.findDetail()
    this.findgoodList()
    this.getvideobase()
  },
  methods: {
    ...mapMutations(["sethdInfo"]),
    setBgColor(){
      if(this.detail.bgcolor!='' && this.detail.bgcolor!=null){
        document.querySelector('body').setAttribute('style', 'background-color:'+this.detail.bgcolor)
      }
    },
    findDetail(){
      GetHdInfo({hdid:this.hdid}).then(result => {
        if(result.data.code==1){
          this.detail=result.data.data
          this.detail.is_adv=(this.detail.is_adv && this.detail.loading)?true:false
          if(!this.detail.loading){
            return false
          }
          this.setBgColor()
          
          this.sethdInfo(this.detail)
          document.title = this.detail.title
          let route = gethdcomponents(this.detail.hd_type)
          this.myComponent = () => import(`./${route}/index.vue`)
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    findgoodList(){
      GetgoodList({hdid:this.hdid}).then(result => {
        if(result.data.code==1){
          this.goodList=result.data.data
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    navigateTo(){
      this.$router.push({path:'/douyin/order/'+this.detail.id})
    },
    relay(){
      this.$dialog.alert({
        title:'提示',
        message: '<p>1.转发商家视频并成功发布</p><p>2.获取点赞数</p><p>3.根据点赞数兑换相应的奖品</p>',
        className:'DialogConfirm',
        confirmButtonText:'确定知道',
        cancelButtonText:'考虑一下',
        showCancelButton:true,
      }).then(() => {
        this.$toast.loading({message: '加载中...',forbidClick: true})
        GetShareSignature({hdid:this.hdid}).then(result => {
          if(result.data.code==1){
            const {client_key,nonceStr,signature,timestamp} = result.data.data.signPackage
            let share_id=result.data.data.share_id
            let url = 'snssdk1128://openplatform/share?share_type=h5&client_key='+client_key+'&nonce_str='+nonceStr+'&timestamp='+timestamp+'&signature='+signature+'&state='+share_id+'&share_to_publish=0&video_path='+this.detail.video_path+'&title='+this.detail.title
            window.location.href = url
          }else{
            this.$toast(result.data.msg)
          }
        })
      }).catch(() => {
      
      })
    },
    getvideobase(){
      this.statistics={
        digg_count:0,
        forward_count:0,
        share_count: 0
      }
      this.$toast.loading({message: '加载中...',forbidClick: true})
      GetVideoExternal({hdid:this.hdid}).then(result => {
        if(result.data.code==1){
          this.myhdinfo=result.data.data
        }else{
          this.$toast(result.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less">
</style>
