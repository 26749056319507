import {
  hd_info,good,
  sharesignature,getvideoexternal,goodexchange,
  orderlist,orderInfo,orderHx,
} from './api'
import {request, METHOD} from '@/utils/request'
//活动基本信息
export async function GetHdInfo(params) {
  return request(hd_info, METHOD.POST,params)
}
//商品列表
export async function GetgoodList(params) {
  return request(good, METHOD.POST,params)
}


export async function GetShareSignature(params) {
  return request(sharesignature, METHOD.POST,params)
}
export async function GetVideoExternal(params) {
  return request(getvideoexternal, METHOD.POST,params)
}
export async function GoodExchange(params) {
  return request(goodexchange, METHOD.POST,params)
}

//我的订单列表
export async function GetorderList(params) {
  return request(orderlist, METHOD.POST,params)
}
//订单详情
export async function GetorderInfo(params) {
  return request(orderInfo, METHOD.POST,params)
}

//核销
export async function OrderHx(params) {
  return request(orderHx, METHOD.POST,params)
}
