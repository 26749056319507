const HTTP_URL=process.env.VUE_APP_API+'/index/'
module.exports = {
  hd_info:`${HTTP_URL}douyin/hdInfo`,
  good:`${HTTP_URL}douyin/good/list`,
  //需授权
  sharesignature:`${HTTP_URL}douyin/user/sharesignature`,
  getvideoexternal:`${HTTP_URL}douyin/user/getvideoexternal`,
  goodexchange:`${HTTP_URL}douyin/user/goodexchange`,

  orderlist:`${HTTP_URL}douyin/user/orderList`,
  orderInfo:`${HTTP_URL}douyin/user/orderInfo`,
  orderHx:`${HTTP_URL}douyin/user/orderHx`
}
